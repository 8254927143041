@import '../../../../scss/theme-bootstrap';

.footer-links {
  font-family: $font--neue-haas-unica-pro;
  color: $color-medium-grey;
  &__content {
    .footer-links__title {
      cursor: pointer;
      font-weight: 500;
      padding: 35px 0;
      font-size: 12px;
      color: $color-light-black;
      border-bottom: 1px solid $color-silver;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include breakpoint($landscape-up) {
        font-size: 14px;
        cursor: default;
        pointer-events: none;
        border-bottom: none;
        padding: 0 0 16px;
      }
      @if $cr22 {
        .mpp-container--dark-background & {
          color: $color-white;
        }
      }
    }
    .text-link {
      font-weight: normal;
      text-decoration: none;
      font-size: 14px;
      color: $color-medium-grey;
      letter-spacing: 0.05em;
      @include breakpoint($landscape-up) {
        font-size: 15px;
      }
      @if $cr22 {
        .mpp-container--dark-background & {
          color: $color-neutral-light-gray;
        }
      }
    }
    .footer-links__mobile-content-toggle {
      display: inline-block;
      float: $rdirection;
      height: 13px;
      width: 13px;
      transition: backgroound 0.3s ease-in;
      @include breakpoint($landscape-up) {
        display: none;
      }
      &.icon--minus {
        display: none;
      }
      &.icon {
        .mpp-container--dark-background & {
          fill: $white;
        }
      }
    }
    &[aria-expanded='true'] {
      .footer-links__title {
        border-bottom: none;
      }
      .footer-links__mobile-content-toggle {
        display: none;
        &.icon--minus {
          display: inline-block;
          @include breakpoint($landscape-up) {
            display: none;
          }
        }
      }
    }
    .footer-links__section {
      height: auto;
      transition: height 0.3s ease-in;
      &[aria-hidden='true'] {
        height: 0;
        overflow: hidden;
        @include breakpoint($landscape-up) {
          height: auto;
        }
      }
    }
  }
  &__link {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 24px;
    }
    .ot-sdk-show-settings {
      cursor: pointer;
    }
  }
}
